@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
  .screen {
    @apply flex flex-col items-center py-16 bg-gray-bg justify-center min-h-screen;
  }
}

@font-face {
  font-family: 'Avenir';
  src:
    local('Avenir'),
    url(../assets/fonts/avenir/Avenir-Light.otf) format('opentype');
  font-weight: 400;
}

@font-face {
  font-family: 'Avenir';
  src:
    local('Avenir'),
    url(../assets/fonts/avenir/AvenirRegular.otf) format('opentype');
  font-weight: 500;
}

@font-face {
  font-family: 'Avenir';
  src:
    local('Avenir'),
    url(../assets/fonts/avenir/Avenir-Bold.otf) format('opentype');
  font-weight: 700;
}

:root {
  --primary: #efa005;
  --success: #31005c;
  --error: #f75555;
  --font-primary: 'Avenir', sans-serif;
}
/* Add these styles in your global styles file or as a styled component */
/* .ant-table {
  @apply border-gray-300 rounded-lg;
}

.ant-table-thead > tr > th {
  @apply bg-BrandPrimary text-gray-700;
}

.ant-table-tbody > tr > td {
  @apply p-4 text-gray-600;
}

:host .ant-pagination-item {
  @apply px-2 py-1 rounded-full border border-transparent !text-BrandPrimary hover:!text-BrandPrimary;
}

:host .ant-pagination-item-active {
  @apply !bg-BrandPrimary text-white !border-BrandPrimary;
}

.ant-pagination-prev,
.ant-pagination-next {
  a {
    @apply text-blue-500 hover:text-blue-700;
  }
} */

html,
body {
  padding: 0;
  margin: 0;
  font-family: 'Avenir', sans-serif;
  font-weight: 400;
  font-size: 16px;
  overflow-x: hidden;
  color: #000000;
}

/* Input styles */
.inputContainer {
  display: flex;
  gap: 10px;
  flex-direction: column;
  position: relative;
}

.inputContainer > label {
  font-family: 'Avenir', sans-serif;
  font-weight: 500;
  font-size: 16px;
  color: #616161;
  gap: 2px;
  display: flex;
}

.inputContainer > * input,
textarea {
  padding-block: 10px;
  padding-inline: 14px;
  font-size: 16px;
  font-family: 'Avenir', sans-serif;
  border: 1px solid #d0d5dd;
  border-radius: 8px;
  width: 100%;
  box-shadow: 0px 1px 2px 0px #1018280d;
  color: #000;
}

.inputContainer > * input::placeholder,
textarea::placeholder {
  color: #9e9e9e;
  font-family: 'Avenir', sans-serif;
  font-weight: 400;
}

.inputError {
  border: 1px solid var(--error) !important;
}

.error {
  color: var(--error);
  font-size: 0.75rem;
}

.errorText {
  color: var(--error) !important;
}

/* styles.css */
.icon-custom {
    color: #EFA005;

  transition: 0.3s;
  
}

.icon-custom:hover {
    color: #31005C;

}

/* Hide scrollbar for Chrome, Safari, and Opera */
.hide-scrollbar::-webkit-scrollbar {
  width: 0px;
  background: transparent; /* Make scrollbar transparent */
}

/* Hide scrollbar for Firefox */
.hide-scrollbar {
  scrollbar-width: none; /* Firefox */
}

/* Show scrollbar on hover */
.hide-scrollbar:hover::-webkit-scrollbar {
  width: 6px; /* Width of the scrollbar when scrolling */
}

.hide-scrollbar:hover::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.3); /* Darker scrollbar thumb */
  border-radius: 10px;
}

.scrollbar-always-visible {
  overflow-y: auto;
}

.scrollbar-always-visible::-webkit-scrollbar {
  width: 4px;
}

.scrollbar-always-visible::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.scrollbar-always-visible::-webkit-scrollbar-thumb {
  background: #888; 
  border-radius: 10px;
}

.scrollbar-always-visible::-webkit-scrollbar-thumb:hover {
  background: #555; 
}

